.verifyContent{
    text-align: left;
}
.verifyContent .verifyTopTitle{
    color: #7cb7b0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
}
.verifyContent .extraTitle{
    color: #4c4d4f;
    font-size: 40px;
    line-height: 42px;
    font-weight: 600;
    margin-bottom: 20px;
}
.verifyBlockImg img{
    width: 24px;
    height: 24px;
    margin-right: 60px;
}
.verifyBlock{
    border: 1px solid rgba(124, 183, 176, 0.22);
    padding: 15px;
    margin:10px 0 20px 0 !important;
    border-radius: 5px;
    background: rgba(124, 183, 176, 0.05);
    text-align: left;
}
.verifyBlock li{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}
.verifyBlock li:last-child{
    margin-bottom: 0;
}
.verifyBlock li .verifyBlockDec{
    color: #4c4d4f;
    font-size: 16px;
    line-height: 22px;
}
.verifyBlock li span.verifyBlockTitle{
    color: #7cb7b0;
    margin-right: 15px;
    font-size: 16px;
    min-width: 90px;
}
.verifyCheckbox{
    margin:10px 0 20px 0;
}
.verifyCheckbox button{
    margin-right:20px;
    padding: 8px 20px 8px;
}
.verifyNote{
    margin-top: 20px;
    color: #7cb7b0;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    line-height: 16px;
}
.verifyNote span:first-child{
    margin-right: 10px;
    min-width: 45px;
    font-weight: bold;
}
.verifyNote span:last-child{
    display: block;
    width: calc(100% - 65px);
    text-align: left;
    line-height: 20px;
}
@media (min-width:768px) and (max-width:1199px){
    .verfiyDevice{
        padding: 0 20px;
    }
}
@media (max-width:767px){
    .verifyCheckbox button{
        margin: 10px 10px 10px 0;
        display: inline-block;
    }
    .verifyContent{
        margin-top: 20px;
    }
    .verifyContent .verifyTopTitle{
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 24px;
    }
    .verifyContent .extraTitle{
        font-size: 22px !important;
        line-height: 24px;
    }
    .verfiyDevice{
        padding: 0 15px;
    }
    .verifyContent h3{
        font-size: 16px;
        line-height: 24px;
    }
}