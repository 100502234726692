.loading-indicator {
  z-index: 2147483647;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loading-indicator:before {
  content: '';
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647;
}

.loading-indicator:after {
  z-index: 2147483647;

  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(/static/media/loader.8997bb48.svg) no-repeat center;
  width: 80px;
  height: 80px;
  background-size: contain;
  margin: auto;
}

.upcoming-row-data > div {
  color: #c950c1 !important;
  font-weight: 600;
}

.upcoming-row-data > div > div {
  color: #c950c1 !important;
  font-weight: 600;
}

.bold-text {
  font-weight: 700 !important;
}
