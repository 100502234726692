/* body {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 0;
    padding: 0;
} */

.container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
}

.header {
    text-align: center;
    margin-top: 20px;
}

.header h1 {
    font-size: 24px;
}

.section {
    margin-top: 30px;
}

.section h2,
.section h3 {
    font-size: 20px;
    color: #0073e6;
}

.section p,
.section ul {
    font-size: 14px;
    color: black;

    margin: 10px 30px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}